<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="h3">
        {{ `${$t('discount.discount')}` }}
      </h1>
      <div class="ml-auto">
        <a-button type="primary" size="large" @click="toAddDiscount(undefined)">
          <a-icon type="plus-square" />
          {{ $t('discount.createDiscount') }}
        </a-button>
      </div>
    </div>
    <a-row :gutter="24" class="mt-3">
      <a-col :span="12">
        <label class="mb-1">&nbsp;</label>
        <a-input-search
          v-model="params.search"
          size="large"
          :placeholder="$t('discount.search')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="onChangeTab"
        >
          <a-button slot="enterButton" class="bg-primary-blue text-white">
            {{ $t('utils.search') }} <a-icon type="search" />
          </a-button>
        </a-input-search>
      </a-col>
      <a-col :span="6" :offset="2">
        <label class="mb-1">{{ $t('discount.period') }}</label>
        <a-range-picker v-model="dates" format="DD MMM YYYY" size="large" @change="onChangeDate" />
      </a-col>
      <a-col :span="4">
        <label class="mb-1">{{ $t('order.sortBy') }}</label>
        <a-dropdown v-model="visible" :trigger="['click']" style="width: 100%">
          <a-menu slot="overlay" @click="onMenuClick">
            <a-menu-item key="1">
              <span class="dropdown-title">{{ $t('discount.sort_by') }}</span>
            </a-menu-item>
            <a-menu-item key="2">
              <a-checkbox-group
                v-model="sorting.sort"
                class="sorting-checkbox"
                :options="sortOptions"
                :value="['createdAt']"
                @change="onSortChange($event, 'sort')"
              />
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="3">
              <span class="dropdown-title">{{ $t('discount.order_by') }}</span>
            </a-menu-item>
            <a-menu-item key="4">
              <a-checkbox-group
                v-model="sorting.order"
                class="sorting-checkbox"
                :options="orderOptions"
                :value="['DESC']"
                @change="onSortChange($event, 'order')"
              />
            </a-menu-item>
          </a-menu>
          <a-button size="large" class="d-flex align-items-center fs-14">
            {{ $t(`discount.${params.sort}`) }}
            <a-icon class="ml-auto" type="down" />
          </a-button>
        </a-dropdown>
      </a-col>
    </a-row>
    <div class="mt-4">
      <a-tabs
        v-model="params.status"
        size="large"
        class="pca-tab-store"
        :disabled="isLoading"
        @change="onChangeStatus"
      >
        <a-tab-pane
          v-for="tab in tabs"
          :key="tab"
          :tab="`${$t('discount.' + tab.toLowerCase())}`"
        />
      </a-tabs>
    </div>
    <div class="mt-2">
      <a-table
        class="discount-list"
        :data-source="discountList"
        :row-key="record => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        :scroll="scroll"
        :row-selection="{ selectedRowKeys: selectedDiscounts, onChange: onSelectChange }"
      >
        <template slot="promotion_name" slot-scope="text, record">
          <p class="mb-1 text-blue-2 fw-bold" style="cursor: pointer" @click="toAddDiscount(record.id, 'detail')">
            {{ text }}
          </p>
          <span class="text-capitalize text-gray-7">
            {{ record.requirements.type }}
          </span>
        </template>
        <template slot="tier" slot-scope="text">
          {{ `${$t('discount.discount_level')} - ${text}` }}
        </template>
        <template slot="start_date" slot-scope="text, record">
          {{ $moment(record.start_date.replace(' ','')).format('DD MMM YYYY HH:mm') + ' - ' + $moment(record.end_date.replace(' ','')).format('DD MMM YYYY HH:mm') }}
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag :color="colors[text]">
            {{ $t(`discount.${text.toLowerCase()}`) }}
          </a-tag>
          <a-popover v-if="text === 'INACTIVE' && record.reason" title="Alasan Pembatalan" style="font-size: 14px; cursor: pointer">
            <template slot="content">
              {{ record.reason }}
            </template>
            <a-icon type="info-circle" style="color: #FF0A54" />
          </a-popover>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-dropdown :overlay-style="overlayStyle">
            <a @click.prevent="">
              <a-icon type="more" />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item v-if="record.status === 'WAITING_APPROVAL' && permission.includes('WRITE')" key="1" class="py-2" @click="toAddDiscount(record.id)">
                  <a-icon type="edit" />
                  {{ $t('discount.edit') }}
                </a-menu-item>
                <a-menu-item key="2" class="py-2" @click="toAddDiscount(record.id, 'detail')">
                  <a-icon type="eye" />
                  {{ $t('store_list_page.view_detail') }}
                </a-menu-item>
                <a-menu-item v-if="record.status === 'WAITING_APPROVAL' && permission.includes('WRITE')" key="3" class="py-2" @click="toAddDiscount(record.id, 'duplicate')">
                  <a-icon type="copy" />
                  Tambah Tier
                </a-menu-item>
                <!-- <a-menu-item key="4" class="py-2">
                  <a-icon type="delete" />
                  {{ $t('utils.delete') }}
                </a-menu-item> -->
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </div>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="totalRow"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListProductDiscount',
}
</script>
<script setup>
import debounce from 'lodash/debounce'
import { computed, getCurrentInstance, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { getPromo } from '@/api/channels/distributor'
import { columnsDiscount } from '@/data/columns'

const { $i18n, $route, $router, $store, $moment } = getCurrentInstance().proxy.$root

const INITIAL_SORT = {
  sort: ['createdAt'],
  order: ['DESC'],
}

const INITIAL_PARAMS = {
  search: '',
  start_date: '',
  end_date: '',
  status: 'ALL',
  sort: 'createdAt',
  order: 'DESC',
  page: 1,
  limit: 10,
}

// Data
const dates = ref([])
const businessId = ref(Object.keys($store.getters['user/businessListObjectByKey'])[0])
const screenHeight = ref(((screen.height - 900) * 0.4) + (screen.height * 0.4))
const isLoading = ref(false)
const visible = ref(false)
const columns = ref(columnsDiscount)
const discountList = ref([])
const totalRow = ref(0)
const selectedDiscounts = ref([])
const tabs = ref(['ALL', 'WAITING_APPROVAL', 'ACTIVE', 'INACTIVE'])
const sizeOptions = ref(['10', '20', '30', '40', '50'])
const sortOptions = ref([
  { label: $i18n.t('discount.createdAt'), value: 'createdAt' },
])
const orderOptions = ref([
  { label: $i18n.t('discount.newest'), value: 'DESC' },
  { label: $i18n.t('discount.oldest'), value: 'ASC' },
])
const sorting = reactive(INITIAL_SORT)
const params = reactive(INITIAL_PARAMS)
const overlayStyle = reactive({ width: '150px' })
const colors = reactive({
  ACTIVE: 'blue',
  WAITING_APPROVAL: 'orange',
  NOT_ACTIVE: 'red',
  INACTIVE: 'red',
})

// Refs
const refSorting = toRefs(sorting)
const refParams = toRefs(params)

// Computed
const business = computed(() => Object.keys($store.getters['user/businessListObjectByKey'])[0])
const scroll = computed(() => ({ y: screenHeight.value > 360 ? screenHeight.value : 360, x: '1000px' }))
const tHeight = computed(() => {
  return !discountList.value.length ? '0px'
    : screenHeight.value > 360 ? `${screenHeight.value}px`
    : '360px'
})
const permission = computed(() => $store.getters['user/can']('discount', 'reverse'))

// Methods
const toAddDiscount = (id, type = '') => {
  console.log(id)
  const query = {
    ...$route.query,
    discount_id: id,
    is_detail: type == 'detail' ? true : undefined,
    is_duplicate: type == 'duplicate' ? true : undefined,
  }

  $router.push({
    path: `/promotion-channel/${$route.params.id}/discount/add`,
    query,
  })
}

const init = () => {
  selectedDiscounts.value = []
  refSorting.value = INITIAL_SORT
  refParams.value = INITIAL_PARAMS
  fetchDiscounts()
}

const fetchDiscounts = debounce(async () => {
  isLoading.value = true
  await getPromo({
    channel_id: $route.params.id,
    params: {
      ...params,
      is_principle: $store.state.user.restriction_base === 'Business',
      type: 'DISCOUNT',
      business_id: businessId.value,
    },
  })
  .then(({ data }) => {
    discountList.value = data?.data || []
    totalRow.value = data?.total_row || 0
  })
  .catch(() => {
    discountList.value = []
    totalRow.value = 0
  })
  .finally(() => isLoading.value = false)
}, 500)

const onMenuClick = (e) => {
  if (e.key !== '2' && e.key !== '4') {
    visible.value = false
  }
}

const onSortChange = (value, type) => {
  const newValue = value.length ? value.filter(val => val !== params[type]) : [params[type]]
  sorting[type] = newValue
  params[type] = newValue.toString()
  fetchDiscounts()
}

const onSelectChange = (value) => {
  selectedDiscounts.value = value
}

const onChangeTab = () => {
  params.page = 1
  fetchDiscounts()
}

const onChangeStatus = (value) => {
  params.page = 1
  params.status = value
  fetchDiscounts()
}

const onChangeDate  = (date) => {
  params.page = 1
  params.start_date = date[0] ? $moment(date[0]).startOf('day').format() : ''
  params.end_date = date[1] ? $moment(date[1]).endOf('day').format() : ''
  fetchDiscounts()
}

const onPageChange = (pageNumber) => {
  selectedDiscounts.value = []
  params.page = pageNumber
  fetchDiscounts()
}

const onShowSizeChange = (current, pageSize) => {
  selectedDiscounts.value = []
  params.page = current
  params.limit = pageSize
  fetchDiscounts()
}

// Watch
watch(() => 
  business.value, (newValue) => {
    businessId.value = newValue
    init()
  })
watch(() => 
  $route, (newValue) => {
    if (newValue.name === $route.name) {
      init()
    }
  })

// Mounted
onMounted(init)
</script>

<style lang="scss">
.fs-14 {
  font-size: 14px !important;
}
.fw-bold {
  font-weight: bold;
}
.text-capitalize {
  text-transform: capitalize
}
.sorting-checkbox {
  .ant-checkbox-group-item {
    display: block !important;
    padding: 5px 0;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-checkbox-inner {
    border-color: transparent !important;
  }
  .ant-checkbox-checked {
    &::after {
      border-color: transparent !important;
    }
    .ant-checkbox-inner {
      background-color: transparent !important;

      &::after {
        border-color: #ff0a54 !important;
      }
    }
  }
}
.dropdown-title {
  font-size: 12px;
  font-weight: bold;
  color: #6c757d;
}
.discount-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
.ant-btn-info {
  color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.ant-dropdown-menu-item {
  background: #fff !important;
}
.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected > td {
    background-color: #fff !important;
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>